
<i18n locale="th" lang="yaml" >
 page.title : "เพิ่ม Drone Model รุ่นใหม่"
 page.description : "เพิ่ม Drone Model รุ่นใหม่ในระบบ"
 create_model.success : "ทำการเพิ่ม Drone รุ่นใหม่ {name} ในระบบเรียบร้อย"

 create_model.submit.label : "เพิ่ม Drone รุ่นใหม่"
 create_model.confirm.title : "ยืนยันการสร้าง Drone Model ใหม่"
 create_model.confirm.message : "คุณต้องการสร้าง Drone Model รุ่นใหม่นี้ ?"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>

		<DroneModelForm
			ref="formRef"
			:loading="loading"
			mode="create"
			@submit="handleSubmit">
			<template v-slot:submitSlot="{ formLoading }">
				<a-button
					:loading="formLoading"
					type="info"
					html-type="submit"
					class="btn-submit"
					icon="edit"
					size="large">
					{{$t('create_model.submit.label')}}
				</a-button>
			</template>
		</DroneModelForm>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import DroneModelForm from '@components/drone/DroneModelForm.vue'
import axios from "axios"

export default {
	page() {
		return {
			title : this.$t('page.title')
		}
	} ,
	components : {
		DroneModelForm,
	} ,
	mixins: [PageMixin],
	data(){
		return {
			loading : false,
		}
	} ,
	methods : {
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('create_model.confirm.title') ,
				content : this.$t('create_model.confirm.message') ,
				maskClosable : true ,
				onOk : () => {
					this.loading = true
					axios.post("/api/drone-models/create",formData).then((response) => {
						const newModel = response.data.data.drone_model
						this.$router.push({
							name : 'drone_model/view',
							params : {id : newModel.id}
						});
						this.$message.success(this.$t('create_model.success',{"name" : newModel.name}))
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false;
					});
				}
			})
		}
	} ,
}
</script>
